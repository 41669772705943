import React from 'react';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';

const LegalDe = () => {
    const {locale} = useIntl();

    return (
        <React.Fragment>
            <p className="c4"><strong>Inhaber</strong><br/><br/>Kadanco UG (haftungsbeschränkt)<br/>
                c/o Maria Richter <br/>Prenzlauer Allee 186<br/>10405 Berlin<br/>GERMANY</p>
            <br/>
            <br/>
            <p className="c4"><strong>Registrierung</strong></p>
            <p className="c4">Amtsgericht Charlottenburg<br/>HRB 206268 B</p>
            <br/>
            <br/>
            <p><strong>Haftung für Inhalte</strong></p>
            <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                oder
                nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                konkreten
                Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese
                Inhalte umgehend entfernen.</p>
            <br/>
            <br/>
            <p><strong>Haftung für Links</strong></p>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                übernehmen.
                Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                erkennbar.
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige
                Links umgehend entfernen.</p>
            <br/>
            <br/>
            <p><strong>Urheberrecht</strong></p>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                Art
                der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
                des
                jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
                als
                solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Inhalte umgehend entfernen.</p>
            <p>© Maria Richter und <Link to={`/${locale}/`}>www.iam-mia.de</Link>. Die unbefugte Nutzung und/oder
                Vervielfältigung dieses Materials
                ohne ausdrückliche und schriftliche Genehmigung des Autors und/oder Eigentümers dieser Website ist
                strengstens untersagt. Auszüge und Links dürfen verwendet werden, vorausgesetzt, dass Maria Richter
                und <Link to={`/${locale}/`}>www.iam-mia.de</Link> vollständig und deutlich genannt werden, mit einem
                angemessenen und spezifischen
                Hinweis auf den ursprünglichen Inhalt.</p>
            <br/>
            <br/>
            <p><strong>Was sind personenbezogene Daten?</strong></p>
            <p>Der Begriff der personenbezogenen Daten ist im Bundesdatenschutzgesetz und in der
                EU-DS-GVO definiert. Danach sind dies Einzelangaben über persönliche oder sachliche Verhältnisse
                einer bestimmten oder bestimmbaren natürlichen Person. Darunter fällt also beispielsweise Ihr
                bürgerlicher Name, Ihre Anschrift, Ihre Telefonnummer oder Ihr Geburtsdatum.</p>
            <br/>
            <br/>
            <p><strong>Umfang der anonymen Datenerhebung und Datenverarbeitung</strong></p>
            <p>Soweit in den folgenden Abschnitten nicht anderweitig dargestellt, werden bei Nutzung
                unserer Webseiten grundsätzlich keine personenbezogenen Daten erhoben, verarbeitet oder genutzt.
                Wir
                erfahren aber über den Einsatz von Analyse- und Trackingtools bestimmte technische Informationen
                anhand der von Ihrem Browser übermittelten Daten (beispielsweise Browsertyp/-version,
                verwendetes
                Betriebssystem, bei uns besuchte Webseiten inkl. Verweildauer, zuvor besuchte Webseite). Diese
                Informationen werten wir nur zu statistischen Zwecken aus.</p>
            <br/>
            <br/>
            <p><strong>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</strong></p>
            <p>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
                betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DS-GVO)
                als
                Rechtsgrundlage für die Verarbeitung personenbezogener Daten.Bei der Verarbeitung von
                personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene
                Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DS-GVO als Rechtsgrundlage. Dies gilt
                auch
                für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                Soweit
                eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
                erforderlich
                ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DS-GVO als Rechtsgrundlage.
                Für
                den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen
                Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d
                DS-GVO als Rechtsgrundlage. Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
                unseres
                Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und
                Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f
                DS-GVO als Rechtsgrundlage für die Verarbeitung.</p>
            <br/>
            <br/>
            <p><strong>Erstellung von Logfiles</strong></p>
            <p>Bei jedem Aufruf der Internetseite erfasst die Kadanco UG (haftungsbeschränkt) durch
                ein automatisiertes System Daten und Informationen. Diese werden in den Logfiles des Servers
                gespeichert. Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine
                Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht
                statt.</p>
            <p>Folgende Daten können hierbei erhoben werden:</p>
            <p>(1) Informationen über den Browsertyp und die verwendete Version</p>
            <p>(2) Das Betriebssystem des Nutzers</p>
            <p>(3) Den Internet-Service Provider des Nutzers</p>
            <p>(4) Die IP-Adresse des Nutzers</p>
            <p>(5) Datum und Uhrzeit des Zugriffs</p>
            <p>(6) Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt
                (Referrer)</p>
            <p>(7) Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden</p>
            <br/>
            <br/>
            <p><strong>Routinemäßige Löschung und Sperrung von personenbezogenen Daten</strong></p>
            <p>Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene
                Daten der betroffenen Person nur solange, wie dies zur Erreichung des Speicherungszwecks
                erforderlich ist. Eine Speicherung kann darüber hinaus dann erfolgen, soweit dies durch den
                europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
                sonstigen
                Vorschriften, denen der für die Verarbeitung verantwortliche unterliegt, vorgesehen wurde.
                Sobald
                der Speicherungszweck entfällt oder eine durch die genannten Vorschriften vorgeschriebene
                Speicherfrist abläuft, werden die personenbezogenen Daten routinemäßig gesperrt oder
                gelöscht.</p>
            <br/>
            <br/>
            <p><strong>Rechte der betroffenen Person</strong></p>
            <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d.
                DS-GVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:</p>
            <br/>
            <br/>
            <p><strong>Auskunftsrecht</strong></p>
            <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob
                personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Liegt eine solche
                Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft
                verlangen:</p>
            <p>a. die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
            <p>b. die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
            <p>c. die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen Ihre
                personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</p>
            <p>d. die geplante Dauer der Speicherung Ihrer personenbezogenen Daten oder, falls
                konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</p>
            <p>e. das Bestehen eines Rechts auf Berichtigung oder Löschung Ihrer personenbezogenen
                Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
                Widerspruchsrechts gegen diese Verarbeitung;</p>
            <p>f. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
            <p>g. alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</p>
            <p>h. das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
                gemäß Art. 22 Abs. 1 und 4 DS-GVO und – zumindest in diesen Fällen – aussagekräftige
                Informationen
                über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer
                derartigen
                Verarbeitung für die betroffene Person.</p>
            <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob Ihre personenbezogenen
                Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem
                Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DS-GVO im
                Zusammenhang
                mit der Übermittlung unterrichtet zu werden.</p>

            <p><strong>Recht auf Berichtigung</strong>
            </p><p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem
            Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig
            oder
            unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
            <br/>
            <br/>
            <p><strong>Recht auf Einschränkung der Verarbeitung</strong></p>
            <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung
                Ihrer personenbezogenen Daten verlangen:</p>
            <p>a. wenn Sie die Richtigkeit Ihrer personenbezogenen Daten für eine Dauer bestreiten,
                die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                überprüfen;</p>
            <p>b. die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten
                ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
                verlangen;</p>
            <p>c. der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung
                nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen benötigen, oder</p>
            <p>d. wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt
                haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren
                Gründen überwiegen.</p>
            <p>Wurde die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt, dürfen diese Daten
                – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder
                eines
                Mitgliedstaats verarbeitet werden. Wurde die Einschränkung der Verarbeitung nach den o.g.
                Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die
                Einschränkung aufgehoben wird.</p>
            <br/>
            <br/>
            <p><strong>Recht auf Löschung</strong></p>
            <p>(1) Sie können von dem Verantwortlichen verlangen, dass Ihre personenbezogenen Daten
                unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet diese Daten unverzüglich
                zu
                löschen, sofern einer der folgenden Gründe zutrifft:</p>
            <p>a. Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie
                erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</p>
            <p>b. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1
                lit. a oder Art. 9 Abs. 2 lit. a DS-GVO stützte, und es fehlt an einer anderweitigen
                Rechtsgrundlage
                für die Verarbeitung.</p>
            <p>c. Sie legen gem. Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein und es
                liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die Sie legen gem.
                Art.
                21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.</p>
            <p>d. Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
            <p>e. Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich,
                dem
                der Verantwortliche unterliegt.</p>
            <p>f. Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste
                der Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.</p>
            <p>(2) Hat der Verantwortliche Ihre personenbezogenen Daten öffentlich gemacht und ist er
                gem. Art. 17 Abs. 1 DS-GVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung
                der
                verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer
                Art,
                um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten,
                darüber
                zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen
                personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten
                verlangt
                hat.</p>
            <p>(3) Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
            <p>a. zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
            <p>b. zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht
                der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur
                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
                Gewalt
                erfolgt, die dem Verantwortlichen übertragen wurde;</p>
            <p>c. aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit
                gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Ab. 3 DS-GVO;</p>
            <p>d. für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
                historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DS-GVO, soweit das
                in
                Abs. 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich
                macht oder ernsthaft beeinträchtigt, oder</p>
            <p>e. zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
            <br/>
            <br/>
            <p><strong>Recht auf Unterrichtung</strong></p>
            <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung
                gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen
                die
                Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung
                der
                Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
                unmöglich
                oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
                Verantwortlichen
                das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
            <br/>
            <br/>
            <p><strong>Recht auf Datenübertragbarkeit</strong></p>
            <p>Sie haben das das Recht, Ihre personenbezogenen Daten, die Sie dem Verantwortlichen
                bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten.
                Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch
                den
                Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
                sofern</p>
            <p>a. die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DS-GVO oder Art.
                9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DS-GVO beruht und</p>
            <p>b. die Verarbeitung mithilfe automatisierter Verfahren erfolgt. In Ausübung dieses
                Rechts haben Sie ferner das Recht zu erwirken, dass Ihre personenbezogenen Daten direkt von
                einem
                Verantwortlichen zu einem anderen Verantwortlichen übermittelt werden, soweit dies technisch
                machbar
                ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden. Das
                Recht
                auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
                Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
                öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
            <br/>
            <br/>
            <p><strong>Widerspruchsrecht</strong></p>
            <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
                jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
                lit.
                e oder f DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                gestütztes Profiling. Der Verantwortliche verarbeitet Ihre personenbezogenen Daten nicht mehr,
                es
                sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen. Werden Ihre personenbezogenen Daten
                verarbeitet, um
                Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
                Ihrer
                personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling,
                soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie der Verarbeitung für
                Zwecke der Direktwerbung, so werden Ihre personenbezogenen Daten nicht mehr für diese Zwecke
                verarbeitet. Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
                Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
                automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</p>
            <p>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</p>
            <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu
                widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der
                Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
            <br/>
            <br/>
            <p><strong>Recht auf Beschwerde bei einer Aufsichtsbehörde</strong></p>
            <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
                Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in
                dem
                Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
                Verstoßes,
                wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DS-GVO
                verstößt. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
                Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit
                eines gerichtlichen Rechtsbehelfs nach Art. 78 DS-GVO.</p>
            <br/>
            <br/>
            <p><strong>Dauer der Speicherung personenbezogener Daten</strong></p>
            <p id="cookies">Personenbezogene Daten werden für die Dauer der jeweiligen gesetzlichen
                Aufbewahrungsfrist gespeichert. Nach Ablauf der Frist erfolgt eine routinemäßige Löschung der
                Daten,
                sofern nicht eine Erforderlichkeit für eine Vertragsanbahnung oder die Vertragserfüllung
                besteht.</p>
            <br/>
            <br/>
            <p><strong>Verwendung von Cookies</strong></p>
            <p>Die Internetseiten der Kadanco UG (haftungsbeschränkt) verwenden Cookies. Bei Cookies
                handelt es sich um Daten, die vom Internetbrowser auf dem Computersystem des Nutzers gespeichert
                werden. Die Cookies können beim Aufruf einer Seite an diese übermittelt werden und ermöglichen
                somit
                eine Zuordnung des Nutzers. Cookies helfen dabei, die Nutzung von Internetseiten für die Nutzer
                zu
                vereinfachen. In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der
                personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DS-GVO. Wir verwenden auf unserer Website
                darüber
                hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen.</p>
            <p>(1) Eingegebene Suchbegriffe</p>
            <p>(2) Häufigkeit von Seitenaufrufen</p>
            <p>(3) Inanspruchnahme von Website-Funktionen</p>
            <p>Es ist jederzeit
                möglich der Setzung von Cookies durch entsprechende Änderung der Einstellung im Internetbrowser
                zu
                widersprechen. Gesetzte Cookies können gelöscht werden. Es wird darauf hingewiesen, dass bei
                Deaktivierung von Cookies möglicherweise nicht alle Funktionen unserer Internetseite
                vollumfänglich
                genutzt werden können. Die auf diese Weise erhobenen Daten der Nutzer werden durch technische
                Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht
                mehr
                möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten der Nutzer
                gespeichert.
                Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner über die Verwendung von
                Cookies zu
                Analysezwecken informiert und auf diese Datenschutzerklärung verwiesen. Es erfolgt in diesem
                Zusammenhang auch ein Hinweis darauf, wie die Speicherung von Cookies in den
                Browsereinstellungen
                unterbunden werden kann. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
                Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f DS-GVO. Die Rechtsgrundlage für
                die
                Verarbeitung personenbezogener Daten unter Verwendung von Cookies zu Analysezwecken ist bei
                Vorliegen
                einer diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO. Ob und in welchem
                Umfang
                Cookies auf unserer Internetseite verwendet werden, entnehmen Sie bitte unserem Cookie-Banner
                und
                unseren Hinweisen in dieser Datenschutzerklärung.</p>
            <br/>
            <br/>
            <p><strong>Google Analytics und Conversion Tracking</strong></p>
            <p>Beschreibung und Zweck</p>
            <p>Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google LLC. (1600
                Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird, zur Analyse der
                Websitebenutzung
                durch Nutzer. Der Dienst verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät
                gespeichert
                werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen
                Google-Server
                in den USA gesandt und dort gespeichert. Ggfs. wird Google Analytics auf dieser Webseite um den
                Code
                „gat._anonymizeIp();“ erweitert genutzt, um eine anonymisierte Erfassung von IP-Adressen (sog.
                IP-Masking) zu gewährleisten. Bitte beachten Sie auch die nachfolgenden Informationen zum
                Einsatz
                von Google Analytics: Diese Website benutzt Google Analytics, einen Webanalysedienst der Google
                LLC.
                („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
                gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
                IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen
                Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im
                Rahmen
                der Vereinbarung zur Auftragsdatenvereinbarung, welche die Websitebetreiber mit der Google LLC
                geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der
                Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene
                Dienstleistungen.</p>
            <br/>
            <br/>
            <p><strong>Rechtsgrundlage</strong></p>
            <p>Rechtsgrundlage für die Verarbeitung von personenbezogenen Daten ist eine Einwilligung
                gem. Art. 6 Abs. 1 lit. a) DSGVO, falls die anonymisierte Datenerfassung mittels des Codes
                “gat._anonymizeIp” nicht stattfindet. Ansonsten, insbesondere im Fall des Einsatzes von
                “gat._anonymizeIp” ist Art. 6 Abs. 1 lit. f) DSGVO die Rechtsgrundlage.</p>
            <br/>
            <br/>
            <p><strong>Empfänger</strong></p>
            <p>Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre
                IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
                anderen
                Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Die durch den
                Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
                Server von Google in den USA übertragen und dort gespeichert.Nur in Ausnahmefällen wird die
                volle
                IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
                Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                Websitebetreiber zu
                erbringen. Zusätzlich nutzen wir im Zusammenhang mit Google Analytics das Google Conversion
                Tracking. Dies ermöglicht uns das Verhalten von unseren Webseitenbesucher zu erfassen. Zum
                Beispiel
                wird uns angezeigt, wie viele PDF’s auf unserer Webseite gedownloadet oder wie oft das
                Kontaktformular ausgefüllt wurde. Ebenso wird uns angezeigt, wie viele Klicks auf Werbeanzeigen
                von
                externen Quellen (AdWords, LinkedIn, Xing, Facebook, Pinterest, Instagram usw.) auf unsere
                Webseite
                geführt haben. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
                nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch
                eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf
                hin,
                dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
                werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
                auf
                Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                Verarbeitung
                dieser Daten durch Google verhindern, indem Sie das unter dem folgenden <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer"
                    target="_blank">Link</a> verfügbare BrowserPlugin herunterladen und
                installieren. Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf
                folgenden
                Link klicken.</p>
            <br/>
            <br/>
            <p><strong>Übermittlung in Drittstaaten</strong></p>
            <p>Die Daten l an einen Server von Google in den USA übertragen und dort gespeichert.Die
                personenbezogenen Daten werden unter dem EU-US Privacy Shield auf Grundlage des
                Angemessenheitsbeschlusses der Europäischen Kommission in die USA übermittelt. Das Zertifikat
                können
                Sie hier abrufen.</p>
            <br/>
            <br/>
            <p><strong>Dauer der Datenspeicherung</strong></p>
            <p>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs
                verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren
                Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
            <br/>
            <br/>
            <p><strong>Widerrufs- und Widerspruchsmöglichkeit</strong></p>
            <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                Sie
                können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                Website
                bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                Google
                verhindern, indem Sie das Browser-Add-on herunterladen und installieren. Opt-Out-Cookies
                verhindern
                die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die Erfassung durch
                Universal
                Analytics über verschiedene Geräte hinweg zu verhindern, müssen Sie das Opt-Out auf allen
                genutzten
                Systemen durchführen. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics
                deaktivieren</p>
            <br/>
            <br/>
            <p><strong>Vertragliche oder gesetzliche Pflicht</strong></p>
            <p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich noch vertraglich
                vorgeschrieben und auch nicht für einen Vertragsabschluss erforderlich. Sie sind auch nicht
                verpflichtet, die personenbezogenen Daten bereitzustellen. Die Nichtbereitstellung hätte jedoch
                unter Umständen zur Folge, dass Sie diese Funktion unserer Webseite nicht bzw. nicht
                vollumfänglich
                nutzen können.</p>
            <br/>
            <br/>
            <p><strong>Weitere Datenschutzhinweise via Link</strong></p>
            <p>Weitergehende Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
                unter: <a href="https://policies.google.com/privacy?hl=de&amp;gl=de" target="_blank"
                          rel="noopener noreferrer">Nutzungsbedingungen</a> und <a
                    href="https://policies.google.com/terms?hl=de&amp;gl=de"
                    target="_blank" rel="noopener noreferrer">
                    Datenschutz
                </a>
            </p>
            <br/>
            <br/>
            <p><strong>Einbindung von übrigen Diensten und Inhalten Dritter</strong></p>
            <p>Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum
                Beispiel Videos, Fonts oder Grafiken von anderen Webseiten eingebunden werden. Dies setzt immer
                voraus, dass die Anbieter dieser Inhalte (nachfolgend bezeichnet als „Dritt-Anbieter“) die
                IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse, könnten sie die Inhalte nicht an den
                Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser
                Inhalte
                erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
                IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss
                darauf, falls die Dritt-Anbieter die IP-Adresse z.B. für statistische Zwecke speichern. Soweit
                dies
                uns bekannt ist, klären wir die Nutzer darüber auf. Wir möchten durch diese Einbindungen unser
                Onlineangebot bereitstellen und verbessern.</p>
            <br/>
            <br/>
            <p><strong>Kontaktformular</strong></p>
            <p>Auf den Internetseiten von
                www.iam-mia.de ist ein Kontaktformular vorhanden, das für die elektronische Kontaktaufnahme
                genutzt
                werden kann. Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse
                möglich. Nimmt
                die betroffene Person über einen dieser Kanäle Kontakt auf, so werden die von der betroffenen
                Person
                übermittelten personenbezogenen Daten automatisch gespeichert. Die Speicherung dient allein zu
                Zwecken
                der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person. Eine Weitergabe der Daten an
                Dritte
                findet nicht statt. Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
                Einwilligung
                des Nutzers Art. 6 Abs. 1 lit. a DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten, die im
                Zuge
                einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DS-GVO. Zielt der
                E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
                Verarbeitung Art. 6 Abs. 1 lit. b DS-GVO. Die Daten werden gelöscht, sobald sie für die
                Erreichung des
                Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der
                Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann
                der
                Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation
                dann,
                wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend
                geklärt ist.
                Die während des Absende-Vorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens
                nach
                einer Frist von sieben Tagen gelöscht.</p>
            <br/>
            <br/>
            <p><strong>Bewerbungen</strong></p>
            <p>Mit der Übermittlung der Bewerbung
                an uns, erklären sich die Bewerber mit der Verarbeitung ihrer Daten zu Zwecken des
                Bewerbungsverfahrens
                entsprechend der in dieser Datenschutzerklärung dargelegten Art und Umfang einverstanden. Soweit
                im
                Rahmen des Bewerbungsverfahrens freiwillig besondere Kategorien von personenbezogenen Daten im
                Sinne des
                Art. 9 Abs. 1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2
                lit. b
                DSGVO (z.B. Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft oder ethnische Herkunft).
                Soweit im
                Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des
                Art. 9
                Abs. 1 DSGVO bei Bewerbern angefragt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9
                Abs. 2
                lit. a DSGVO (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung erforderlich sind).
                Sofern zur
                Verfügung gestellt, können uns Bewerber ihre Bewerbungen mittels eines Onlineformulars auf
                unserer
                Website übermitteln. Die Daten werden entsprechend dem Stand der Technik verschlüsselt an uns
                übertragen. Ferner können Bewerber uns ihre Bewerbungen via E-Mail übermitteln. Hierbei bitten
                wir
                jedoch zu beachten, dass E-Mails grundsätzlich nicht verschlüsselt versendet werden und die
                Bewerber
                selbst für die Verschlüsselung sorgen müssen. Wir können daher für den Übertragungsweg der
                Bewerbung
                zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen und
                empfehlen
                daher eher ein Online-Formular oder den postalischen Versand zu nutzen. Denn statt der Bewerbung
                über
                das Online-Formular und E-Mail, steht den Bewerbern weiterhin die Möglichkeit zur Verfügung, uns
                die
                Bewerbung auf dem Postweg zuzusenden. Die von den Bewerbern zur Verfügung gestellten Daten,
                können im
                Fall einer erfolgreichen Bewerbung für die Zwecke des Beschäftigungsverhältnisses von uns
                weiterverarbeitet werden. Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht
                erfolgreich
                ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber werden ebenfalls gelöscht,
                wenn eine
                Bewerbung zurückgezogen wird, wozu die Bewerber jederzeit berechtigt sind. Die Löschung erfolgt,
                vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem Ablauf eines Zeitraums von
                sechs
                Monaten, damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und unseren
                Nachweispflichten
                aus dem Gleichbehandlungsgesetz genügen können. Rechnungen über etwaige Reisekostenerstattung
                werden
                entsprechend den steuerrechtlichen Vorgaben archiviert.</p>
        </React.Fragment>
    );
};

export default LegalDe;
