import React from 'react';
import {Typography} from 'antd';
import {useIntl} from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';
import LegalDe from '../components/legal/LegalDe';
import LegalEn from '../components/legal/LegalEn';
import Layout from "../components/layout";
import {graphql} from 'gatsby';

const Legal = ({data}) => {
    const {Title} = Typography;
    const {formatMessage, locale} = useIntl();

    return (
        <Layout data={data}>
            <Helmet>
                <title>{formatMessage({id: 'legal.page-title'})}</title>
                <meta name="description" content={formatMessage({id: 'legal.page-description'})} />
            </Helmet>
            <div className="container">
                <div className="section section--white">
                    <Title level={1} className="title show-md-up">{formatMessage({id: 'legal.title.desktop'})}</Title>
                    <Title level={1} className="title show-md-down">{formatMessage({id: 'legal.title.mobile'})}</Title>
                    {locale === 'de' ? <LegalDe/> : <LegalEn/>}
                </div>
            </div>
        </Layout>
    );
};

export default Legal;

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;