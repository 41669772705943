import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';

const LegalEn = () => {
    const {locale} = useIntl();

    return (
        <React.Fragment>
            <p className="c4"><strong>Owner</strong><br/><br/>Kadanco UG (haftungsbeschränkt)<br/>
                c/o Maria Richter <br/>Prenzlauer Allee 186<br/>10405 Berlin<br/>GERMANY</p>
            <br/>
            <br/>
            <p className="c4"><strong>Registration</strong></p>
            <p className="c4">Amtsgericht Charlottenburg<br/>HRB 206268 B</p>
            <br/>
            <br/>
            <p><strong>Liability for Content</strong></p>
            <p>As a service provider, we are responsible for our own content on these pages in accordance with general
                laws in accordance with Section 7 (1) TMG. According to §§ 8 to 10 TMG, we as a service provider are not
                obliged to monitor transmitted or stored third-party information or to investigate circumstances that
                indicate illegal activity. Obligations to remove or block the use of information according to general
                laws remain unaffected. However, liability in this regard is only possible from the time we become aware
                of a specific legal violation. As soon as we become aware of such violations, we will remove this
                content immediately.</p>
            <br/>
            <br/>
            <p><strong>Liability for Links</strong></p>
            <p>Our offer contains links to external websites of third parties, the content of which we have no influence
                on. For this reason, we cannot accept any liability for this external content. The respective provider
                or operator of the pages is always responsible for the content of the linked pages. The linked pages
                were checked for possible legal violations at the time the link was created. No illegal content was
                discernible at the time the link was created. A permanent control of the content of the linked pages is
                not reasonable without concrete evidence of an infringement. As soon as we become aware of legal
                violations, we will remove such links immediately.</p>
            <br/>
            <br/>
            <p><strong>Copyright</strong></p>
            <p>The content and works on these pages created by the site operator are subject to German copyright law.
                Duplication, editing, distribution and any kind of use outside the limits of copyright law require the
                written consent of the respective author or creator. Downloads and copies of this page are only
                permitted for private, non-commercial use. As far as the content on this page was not created by the
                operator, the copyrights of third parties are respected. In particular contents of third parties are
                marked as such. Should you nevertheless become aware of a copyright infringement, please let us know. As
                soon as we become aware of legal violations, we will remove such content immediately.</p>
            <p>© Maria Richter and <Link to={`/${locale}/`}>www.iam-mia.de</Link>. Unauthorized use and/or duplication
                of this material without express
                and written permission from this site’s author and/or owner is strictly prohibited. Excerpts and links
                may be used, provided that full and clear credit is given to Maria Richter and
                <Link to={`/${locale}/`}>www.iam-mia.de</Link> with
                appropriate and specific direction to the original content.</p>
            <br/>
            <br/>
            <p><strong>What is Personal Data?</strong></p>
            <p>The concept of personal data is defined in the Federal Data Protection Act and in the EU GDPR. According
                to this, this is individual information about the personal or factual circumstances of a specific or
                identifiable natural person. This includes, for example, your real name, your address, your telephone
                number or your date of birth.</p>
            <br/>
            <br/>
            <p><strong>Scope of Anonymous Data Collection and Data Processing</strong></p>
            <p>Unless otherwise stated in the following sections, no personal data is collected, processed or used when
                using our website. However, we learn certain technical information about the use of analysis and
                tracking tools based on the data transmitted by your browser (e.g. browser type / version, operating
                system used, websites visited by us, including length of stay, previously visited website). We only
                evaluate this information for statistical purposes.</p>
            <br/>
            <br/>
            <p><strong>Legal Basis for the Processing of Personal Data</strong></p>
            <p>Insofar as we obtain the data subject's consent for the processing of personal data, Art. 6 para. 1 lit.
                a EU General Data Protection Regulation (GDPR) serves as the legal basis for the processing of personal
                data. When processing personal data that is necessary for the performance of a contract to which the
                data subject is a party, Art. 6 (1) lit. b GDPR serves as the legal basis. This also applies to
                processing operations that are necessary to carry out pre-contractual measures. Insofar as the
                processing of personal data is necessary to fulfill a legal obligation to which our company is subject,
                Art. 6 para. 1 lit. c GDPR serves as the legal basis. In the event that vital interests of the data
                subject or another natural person require the processing of personal data, Art. 6 para. 1 lit. d GDPR
                serves as the legal basis. If processing is necessary to safeguard the legitimate interests of our
                company or a third party and if the interests, fundamental rights and freedoms of the data subject do
                not outweigh the first-mentioned interest, Art. 6 (1) lit. f GDPR serves as the legal basis for
                processing.</p>
            <br/>
            <br/>
            <p><strong>Creation of Log Files</strong></p>
            <p>Each time the website is accessed, Kadanco UG (limited liability) collects data and information through
                an automated system. These are saved in the log files of the server. The data is also stored in the log
                files of our system. This data is not stored together with other personal data of the user.</p>
            <p>The following data can be collected:</p>
            <p>(1) Information about the browser type and the version used</p>
            <p>(2) The user's operating system</p>
            <p>(3) The user's Internet service provider</p>
            <p>(4) The user's IP address</p>
            <p>(5) Date and time of access</p>
            <p>(6) Websites from which the user's system reaches our website (referrer)</p>
            <p>(7) Websites that are accessed by the user's system via our website</p>
            <br/>
            <br/>
            <p><strong>Routine Deletion and Blocking of Personal Data</strong></p>
            <p>The controller processes and stores personal data of the data subject only as long as is necessary to
                achieve the storage purpose. Storage can also take place insofar as this has been provided for by
                European or national legislators in Union law regulations, laws or other regulations to which the person
                responsible for processing is subject. As soon as the storage purpose no longer applies or a storage
                period prescribed by the aforementioned regulations expires, the personal data will be routinely blocked
                or deleted.</p>
            <br/>
            <br/>
            <p><strong>Rights of the Data Subject</strong></p>
            <p>If personal data is processed by you, you are the person concerned in the sense of GDPR and you have the
                following rights vis-à-vis the person responsible:</p>
            <br/>
            <br/>
            <p><strong>Right of Providing Information</strong></p>
            <p>You can ask the person responsible to confirm whether we process personal data relating to you. If such
                processing is available, you can request the following information from the person responsible:</p>
            <p>a. the purposes for which the personal data are processed;</p>
            <p>b. the categories of personal data that are processed;</p>
            <p>c. the recipients or categories of recipients to whom your personal data has been or will be disclosed;</p>
            <p>d. the planned duration of storage of your personal data or, if specific information on this is not
                possible, criteria for determining the storage period;</p>
            <p>e. the existence of a right to correction or deletion of your personal data, a right to restriction of
                processing by the person responsible or a right to object to this processing;</p>
            <p>f. the existence of a right to correction or deletion of your personal data, a right to restriction of
                processing by the person responsible or a right to object to this processing;</p>
            <p>g. all available information about the origin of the data if the personal data is not collected from the
                data subject;</p>
            <p>h. the existence of automated decision-making, including profiling, in accordance with Art. 22 Para. 1
                and 4 GDPR and - at least in these cases - meaningful information about the logic involved and the scope
                and intended effects of such processing for the data subject.</p>
            <p>You have the right to request information as to whether your personal data will be transferred to a third
                country or to an international organization. In this context, you can request the appropriate guarantees
                in accordance with Art. 46 GDPR to be informed in connection with the transmission.</p>

            <p><strong>Right to Rectification</strong></p>
            <p>You have a right to correction and / or completion vis-à-vis the person responsible if the processed
            personal data concerning you is incorrect or incomplete. The person responsible must make the correction
            immediately.</p>
            <br/>
            <br/>
            <p><strong>Right to Restriction of Processing</strong></p>
            <p>You can request that the processing of your personal data be restricted under the following conditions:</p>
            <p>a. if you contest the accuracy of your personal data for a period of time that enables the person
                responsible to check the accuracy of the personal data;</p>
            <p>b. the processing is unlawful and you refuse to delete the personal data and instead request that the use
                of the personal data be restricted;</p>
            <p>c. the controller no longer needs the personal data for the purposes of processing, but you need them to
                assert, exercise or defend legal claims, or</p>
            <p>d. if you have objected to processing in accordance with Art. 21 Para. 1 GDPR and it is not yet certain
                whether the legitimate reasons of the person responsible outweigh your reasons.</p>
            <p>If the processing of your personal data has been restricted, this data - apart from its storage - may
                only be obtained with your consent or to assert, exercise or defend legal claims or to protect the
                rights of another natural or legal person or for reasons of important public interest in the Union or a
                Member State. If the restriction of processing according to the above You will be informed by the person
                responsible before the restriction is lifted.</p>
            <br/>
            <br/>
            <p><strong>Right to Deletion</strong></p>
            <p>(1) You can request the data controller to delete your personal data immediately, and the data controller
                is obliged to delete this data immediately if one of the following reasons applies:</p>
            <p>a. The personal data relating to you are no longer necessary for the purposes for which they were
                collected or otherwise processed.</p>
            <p>b. You revoke your consent on which the processing was based in accordance with Art. 6 para. 1 lit. a or
                Art. 9 Para. 2 lit. a GDPR was based, and there is no other legal basis for the processing.</p>
            <p>c. According to Art. 21 para. 1 GDPR and the processing is objectionable and there are no overriding
                legitimate reasons for the processing, or you file an objection pursuant to Art. Art. 21 para. 2 GDPR to
                object to processing.</p>
            <p>d. The personal data concerning you have been unlawfully processed.</p>
            <p>e. The deletion of your personal data is necessary to fulfill a legal obligation under Union law or the
                law of the member states to which the controller is subject.</p>
            <p>f. The personal data concerning you was collected in relation to information society services offered in
                accordance with Art. 8 Para. 1 GDPR.</p>
            <p>(2) Has the person responsible made your personal data public and is he acc. 17 para. 1 GDPR to delete
                them, he takes appropriate measures, including technical ones, taking into account the available
                technology and the implementation costs, in order to inform those responsible for data processing who
                process the personal data that you as the data subject, has asked them to delete all links to this
                personal data or copies or replications of this personal data.</p>
            <p>(3) The right to erasure does not exist insofar as processing is necessary</p>
            <p>a. to exercise the right to freedom of expression and information;</p>
            <p>b. to fulfill a legal obligation that requires processing under the law of the Union or the Member States
                to which the controller is subject, or to perform a task that is in the public interest or in the
                exercise of official authority vested in the controller;</p>
            <p>c. for reasons of public interest in the area of ​​public health in accordance with Art. 9 para. 2 lit. h
                and i as well as Art. 9 para. 3 GDPR;</p>
            <p>d. for archiving purposes in the public interest, scientific or historical research purposes or for
                statistical purposes acc. Art. 89 Para. 1 GDPR, insofar as the right mentioned in Paragraph 1 is likely
                to make the achievement of the objectives of this processing impossible or seriously impair it, or</p>
            <p>e. to assert, exercise or defend legal claims.</p>
            <br/>
            <br/>
            <p><strong>Right to be informed</strong></p>
            <p>If you have asserted the right to correction, deletion or restriction of processing against the person
                responsible, he is obliged to inform all recipients to whom the personal data concerning you have been
                disclosed of this correction or deletion of the data or restriction of processing, unless this proves to
                be impossible or involves a disproportionate effort. You have the right vis-à-vis the person responsible
                to be informed about these recipients</p>
            <br/>
            <br/>
            <p><strong>Right to Data Portability</strong></p>
            <p>You have the right to receive your personal data, which you have provided to the person responsible, in a
                structured, common and machine-readable format. You also have the right to transfer this data to another
                controller without hindrance from the controller to whom the personal data has been provided, provided
                that.</p>
            <p>a. processing based on consent in accordance with Art. 6 para. 1 lit. a GDPR or Art. 9 Para. 2 lit. a
                GDPR or on a contract acc. Art. 6 para. 1 lit. b GDPR is based and</p>
            <p>b. processing is carried out using automated processes. In exercising this right, you also have the right
                to have your personal data transferred directly from one controller to another, insofar as this is
                technically feasible. The freedoms and rights of other people must not be affected by this. The right to
                data portability does not apply to the processing of personal data that is necessary for the performance
                of a task that is in the public interest or in the exercise of official authority that has been
                transferred to the person responsible.</p>
            <br/>
            <br/>
            <p><strong>Right to Object</strong></p>
            <p>You have the right, for reasons that arise from your particular situation, at any time against the
                processing of your personal data, which is based on Art. 6 Para. 1 lit. e or f DS-GVO takes place to
                object; this also applies to profiling based on these provisions. The person responsible no longer
                processes your personal data, unless he can demonstrate compelling legitimate grounds for processing
                that outweigh your interests, rights and freedoms, or the processing serves to assert, exercise or
                defend legal claims. If your personal data are processed for direct marketing purposes, you have the
                right to object at any time to the processing of your personal data for the purpose of such advertising;
                this also applies to profiling insofar as it is connected to such direct advertising. If you object to
                processing for direct marketing purposes, your personal data will no longer be processed for these
                purposes. Regardless of Directive 2002/58 / EC, you have the option of exercising your right to object
                in connection with the use of information society services using automated procedures that use technical
                specifications.</p>
            <p>Right to withdraw the data protection declaration of consent</p>
            <p>You have the right to withdraw your declaration of consent under data protection law at any time.
                Withdrawing consent does not affect the lawfulness of processing based on consent before its
                withdrawal.</p>
            <br/>
            <br/>
            <p><strong>Right to Lodge a Complaint with a Supervisory Authority</strong></p>
            <p>Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint
                with a supervisory authority, in particular in the member state of your residence, your place of work or
                the place of the alleged infringement, if you believe that the processing of your personal data violates
                the GDPR offends. The supervisory authority to which the complaint has been submitted will inform the
                complainant of the status and results of the complaint, including the possibility of a judicial remedy
                under Article 78 GDPR.</p>
            <br/>
            <br/>
            <p><strong>Duration of Storage of Personal Data</strong></p>
            <p id="cookies">Personal data is stored for the duration of the respective statutory retention period. After
                the period has expired, the data will be routinely deleted unless there is a need for initiating a
                contract or fulfilling the contract.</p>
            <br/>
            <br/>
            <p><strong>Use of Cookies</strong></p>
            <p>The Kadanco UG website (limited liability) uses cookies. Cookies are data that are stored by the internet
                browser on the user's computer system. The cookies can be transmitted to a page when it is called up and
                thus allow the user to be assigned. Cookies help to make the use of websites easier for users. Our
                legitimate interest in the processing of personal data in accordance with Art. 6 Para. 1 lit. f GDPR. We
                also use cookies on our website that enable an analysis of the surfing behavior of users.</p>
            <p>(1) Entered search terms</p>
            <p>(2) Frequency of page views</p>
            <p>(3) Use of website functions</p>
            <p>It is possible to object to the setting of cookies at any time by changing the setting in the Internet
                browser accordingly. Set cookies can be deleted. Please note that if cookies are deactivated, not all
                functions of our website may be used to their full extent. The user data collected in this way is
                pseudonymized using technical precautions. It is therefore no longer possible to assign the data to the
                accessing user. The data is not stored together with other personal data of the users. When our website
                is called up, an information banner informs users about the use of cookies for analysis purposes and
                refers them to this data protection declaration. In this context, there is also a note on how the
                storage of cookies can be prevented in the browser settings. The legal basis for the processing of
                personal data using technically necessary cookies is Art. 6 Para. 1 lit. f GDPR. The legal basis for the
                processing of personal data using cookies for analysis purposes is the consent of the user, Art. 6 para.
                1 lit. a GDPR. Whether and to what extent cookies are used on our website can be found in our cookie
                banner and our information in this data protection declaration.</p>
            <br/>
            <br/>
            <p><strong>Google Analytics and Conversion Tracking</strong></p>
            <p>Description and purpose</p>
            <p>This website uses the "Google Analytics" service, which is provided by Google LLC. (1600 Amphitheater
                Parkway Mountain View, CA 94043, USA) is offered to analyze website usage by users. The service uses
                "cookies" - text files that are stored on your device. The information collected by the cookies is
                usually sent to a Google server in the USA and stored there. If necessary. Google Analytics is expanded
                on this website by the code "gat._anonymizeIp ();" to ensure anonymous collection of IP addresses
                (so-called IP masking). Please also note the following information on the use of Google Analytics: This
                website uses Google Analytics, a web analytics service provided by Google LLC. ("Google"). Google
                Analytics uses so-called "cookies", text files that are stored on your computer and that enable an
                analysis of your use of the website. The user's IP address is shortened within the member states of the
                EU and the European Economic Area. This shortening eliminates the personal reference to your IP address.
                As part of the agreement on the order data agreement, which the website operator has concluded with
                Google LLC, the website operator uses the information collected to evaluate website usage and website
                activity and provides services related to internet usage.</p>
            <br/>
            <br/>
            <p><strong>Legal Basis</strong></p>
            <p>The legal basis for the processing of personal data is consent in accordance with Art. 6 para. 1 lit. a)
                GDPR, if the anonymized data collection using the code “gat._anonymizeIp” does not take place.
                Otherwise, especially if “gat._anonymizeIp” is used, Art. 6 Para. 1 lit. f) GDPR is the legal basis.</p>
            <br/>
            <br/>
            <p><strong>Receiver</strong></p>
            <p>If IP anonymization is activated on this website, your IP address will be shortened beforehand by Google
                within member states of the European Union or in other contracting states of the Agreement on the
                European Economic Area. The information generated by the cookie about your use of this website is
                usually transferred to a Google server in the USA and stored there. Only in exceptional cases will the
                full IP address be transferred to a Google server in the USA and shortened there. On behalf of the
                operator of this website, Google will use this information to evaluate your use of the website, to
                compile reports on website activity and to provide the website operator with other services related to
                website activity and internet usage. In addition, we use Google Conversion Tracking in connection with
                Google Analytics. This enables us to record the behavior of our website visitors. For example, we are
                shown how many PDF’s have been downloaded from our website or how often the contact form has been filled
                out. We are also shown how many clicks on advertisements from external sources (AdWords, LinkedIn, Xing,
                Facebook, Pinterest, Instagram, etc.) led to our website. The IP address transmitted by your browser as
                part of Google Analytics will not be merged with other Google data. You can prevent the storage of
                cookies by setting your browser software accordingly; however, we would like to point out that in this
                case you may not be able to use all functions of this website to their full extent. You can also prevent
                Google from collecting the data generated by the cookie and relating to your use of the website
                (including your IP address) and from processing this data by Google by clicking on the following <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en" rel="noopener noreferrer"
                    target="_blank">link</a>. Download and install the available browser plug-in.
                You can prevent Google Analytics from collecting data by clicking on the following link.</p>
            <br/>
            <br/>
            <p><strong>Transmission to Third Countries</strong></p>
            <p>The data l is transferred to a Google server in the USA and stored there. The personal data is
                transmitted to the USA under the EU-US Privacy Shield based on the adequacy decision of the European
                Commission. You can access the certificate here.</p>
            <br/>
            <br/>
            <p><strong>Duration of Data Storage</strong></p>
            <p>The data sent by us and linked with cookies, user IDs (e.g. user ID) or advertising IDs will be
                automatically deleted after 14 months. Data whose retention period has expired is automatically deleted
                once a month.</p>
            <br/>
            <br/>
            <p><strong>Revocation and Objection Options</strong></p>
            <p>You can prevent the storage of cookies by setting your browser software accordingly; however, we would
                like to point out that in this case you may not be able to use all functions of this website to their
                full extent. You can also prevent Google from collecting the data generated by the cookie and relating
                to your use of the website (including your IP address) and from processing this data by Google by
                downloading and installing the browser add-on. Opt-out cookies prevent the future collection of your
                data when you visit this website. In order to prevent the acquisition by Universal Analytics across
                different devices, you have to carry out the opt-out on all used systems. If you click here, the opt-out
                cookie will be set: Deactivate Google Analytics.</p>
            <br/>
            <br/>
            <p><strong>Contractual or Legal Obligation</strong></p>
            <p>The provision of personal data is neither required by law nor by contract and is also not required for
                the conclusion of a contract. You are also not obliged to provide the personal data. However, failure to
                provide this could mean that you may not be able to use this function on our website, or not at all.</p>
            <br/>
            <br/>
            <p><strong>Further Data Protection Information via Link</strong></p>
            <p>Further information on terms of use and data protection can be found at: <a href="https://policies.google.com/privacy?hl=en&amp;gl=en" target="_blank"
                          rel="noopener noreferrer">Privacy Policy</a> and <a
                    href="https://policies.google.com/terms?hl=en&amp;gl=en"
                    target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </a>
            </p>
            <br/>
            <br/>
            <p><strong>Integration of other Services and Content from Third Parties</strong></p>
            <p>It may happen that third-party content, such as videos, fonts or graphics from other websites, is
                integrated into this online offer. This always presupposes that the providers of this content
                (hereinafter referred to as "third-party providers") perceive the IP address of the users. Because
                without the IP address, they could not send the content to the browser of the respective user. The IP
                address is therefore required to display this content. We strive to only use content whose respective
                providers only use the IP address to deliver the content. However, we have no influence on whether the
                third-party provider uses the IP address e.g. save for statistical purposes. As far as we know, we will
                inform the users about it. We want to provide and improve our online offering through these
                integrations.</p>
            <br/>
            <br/>
            <p><strong>Contact Form</strong></p>
            <p>A contact form is available on the www.iam-mia.de website that can be used to contact us electronically.
                Alternatively, you can contact us via the email address provided. If the data subject contacts via one
                of these channels, the personal data transmitted by the data subject are automatically saved. The
                storage serves solely for the purposes of processing or contacting the data subject. A transfer of data
                to third parties does not take place. The legal basis for processing the data is Art. 6 (1) lit. a GDPR.
                The legal basis for the processing of data transmitted in the course of sending an email is Art. 6 para.
                1 lit. f GDPR. If the email contact aims to conclude a contract, then an additional legal basis for the
                processing is Art. 6 Para. 1 lit. b GDPR. The data will be deleted as soon as it is no longer required
                to achieve the purpose for which it was collected. For the personal data from the input mask of the
                contact form and those that were sent by email, this is the case when the respective conversation with
                the user has ended. The conversation is ended when it can be inferred from the circumstances that the
                matter in question has been finally clarified. The additional personal data collected during the sending
                process will be deleted after a period of seven days at the latest.</p>
            <br/>
            <br/>
            <p><strong>Applications</strong></p>
            <p>By submitting the application to us, the applicants consent to the processing of their data for the
                purposes of the application process in accordance with the type and scope set out in this data
                protection declaration. If special categories of personal data within the meaning of Art. 9 Para. 1 GDPR
                are voluntarily communicated within the scope of the application process, their processing is also
                carried out in accordance with Art. 9 Para. 2 lit. b GDPR (e.g. health data, such as severely disabled
                status or ethnic origin). Insofar as special categories of personal data within the meaning of Art. 9
                Para. 1 GDPR are requested from applicants within the application process, their processing is also
                carried out in accordance with Art. 9 Para. 2 lit. a GDPR (e.g. health data if this is necessary for the
                exercise of a profession). If provided, applicants can send us their applications using an online form
                on our website. The data is encrypted and transmitted to us in accordance with the state of the art.
                Applicants can also send us their applications via email. However, please note that emails are generally
                not sent in encrypted form and applicants must ensure that they are encrypted themselves. We can
                therefore not assume any responsibility for the transmission path of the application between the sender
                and receipt on our server and therefore recommend using an online form or sending by post. Because
                instead of applying via the online form and e-mail, applicants still have the option to send us the
                application by post. The data provided by the applicants can be processed by us in the event of a
                successful application for the purposes of the employment relationship. Otherwise, if the application
                for a job offer is not successful, the applicant's data will be deleted. Applicant data will also be
                deleted if an application is withdrawn, which applicants are entitled to do at any time. The deletion
                takes place, subject to a justified revocation of the applicants, after a period of six months, so that
                we can answer any follow-up questions to the application and meet our obligations to provide evidence
                from the Equal Treatment Act. Invoices for any reimbursement of travel expenses are archived in
                accordance with tax regulations.</p>
        </React.Fragment>
    );
};

export default LegalEn;
